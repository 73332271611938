import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Etch and clean`}</h4>
    <p>{`After photolithography creates a pattern in the photoresist coating a wafer, specialized tools etch the pattern into the permanent layer below the photoresist. The photoresist is then removed and the etched material cleaned off of the layer. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      